import React from 'react';
import { Col, Row, Table, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileDownload, faEdit, faCog, faDownload} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { getStock, getUserData, getPdf } from '../../api/info';
import gramoca from './../../assets/gramoca.png';
import loading from './../../assets/loading.gif'
import out from './../../assets/out.png'
import {pdf} from './../../api/constants';

class Stock extends React.Component{
  
  constructor(props){
    let obj = new Date();
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    super(props);
    this.state = {
      token: sessionStorage.getItem('token') === null ? localStorage.getItem('token') : sessionStorage.getItem('token'),
      stock: [<img src={loading} alt="cargando" className="center"/>],
      date : obj.toLocaleDateString(undefined, options),
      time: obj.toLocaleTimeString('en-US'),
      userData: props.dataUser,
      tasa: props.tasa,
      adminOptions: props.dataUser.rol === '1' ? <><DropdownItem><Link to={'/adminPanel'} className='linkClick'>Vendedores <FontAwesomeIcon icon={faEdit}/></Link></DropdownItem></> : '',
      codigo: '',
      articulo: '',
      tipoP: [],
    }
  }

  handleChange(e, input) {
    const value = e.target.value;
    this.setState({
      [input]:value
    })
  }

  async componentDidMount(){
    //get data del usuario para saber los precios
    //enviar los precios al back para traer la informacion 
    //guardar info del back en un state para mostrar cada tipo de precio
    let { stock, adminOptions, token, tipoP } = this.state;
    let json = {'token': sessionStorage.getItem('token')}
    let user = await getUserData(json);
    let data = JSON.parse(user);
    data.precios.map(tipo => {
      tipoP.push('P'+tipo)
      return true
    })
    this.setState({
      tipoP: tipoP,
    })
    //console.log(tipoP)

    let inv = await getStock({'precios':tipoP});
    //console.log(inv) 
    stock = JSON.parse(inv);
    //console.log(stock)
    this.setState({
      stock: stock,
      adminOptions: adminOptions,
    })
  }

  search(){
    if(this.state.codigo !== ''){
      return this.state.stock.filter(s => s.co_art.toLowerCase().includes((this.state.codigo).toLowerCase()));
    }else if(this.state.articulo !== ''){
      return this.state.stock.filter(s => s.art_des.toLowerCase().includes((this.state.articulo).toLowerCase()));
    }else{
      return this.state.stock;
    }
  }

  dobleSearch(){
    if(this.state.codigo !== '' && this.state.articulo !== ''){
      //console.log('ok')
      return (this.state.stock.filter(s => s.co_art.toLowerCase().includes((this.state.codigo).toLowerCase()))).filter(x => x.art_des.toLowerCase().includes((this.state.articulo).toLowerCase()))
    }else{
      return this.state.stock;
    }
  }

  cerrarSesion(){
    sessionStorage.removeItem('token')
    window.location.reload();
  }

  PDF(){ 
    window.location = `http://inventario.gramoca.com.ve/back_gramocainv/PDF/generar.php?token=${this.state.token}`
  }

  render(){
    if(this.state.userData.rol !== '1'){
    setTimeout(this.cerrarSesion, 300000);
    }
    let stock = this.search();
    if(this.state.codigo !== '' && this.state.articulo !== ''){
      stock = this.dobleSearch()
    }
    return(
      <>
        <Row className="mt-3">
          <Col md={6} sm={6} xs={6}>
            <a href='https://gramoca.com.ve' target='_blank'><img src={gramoca} alt="GRAMOCA, C.A." className="w-100 pointer" title='Ir al sitio oficial'/></a>
          </Col>
          <Col md={6} className="alignEnd">
            <div className="pointer mb-3 pull-rigth">
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle 
              caret
              tag="span"
              data-toggle="dropdown"
              aria-expanded={this.state.dropdownOpen}
              > <b>{this.state.userData.nombre_apellido} <FontAwesomeIcon icon={faCog}/></b> 
              </DropdownToggle>
              <DropdownMenu>
                {this.state.adminOptions}
                <DropdownItem onClick={() => this.cerrarSesion()}>Cerrar Sesión <img src={out} alt="cerrar sesión" style={{width: '20px'}}/></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>         
            </div>
            <p style={{marginBottom: '0'}}>{this.state.date}</p>
            <p style={{marginBottom: '0'}}>Hora: {this.state.time}</p>
            <p><b>Tasa: {this.state.tasa} Bs.</b></p>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12} sx={12} className="text-center mt-4 d-flex">
            <div className="w-100 text-center">
              <h4>Listado de Precios</h4>
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col md={5} xs={6} sm={6} style={{paddingLeft: 0}}>
            <Input 
            placeholder="Buscar por código"
            name="codigo"
            onChange={event => this.handleChange(event, "codigo")}
            />
          </Col>
          <Col md={5} xs={6} sm={6} className="mb-3">
            <Input 
            placeholder="Buscar por nombre del articulo" 
            name='articulo'
            onChange={event => this.handleChange(event, "articulo")}/>
          </Col>
          <Col md={2} xs={10} sm={10} style={{paddingRight: 0}} className="center mb-3">
            <Button className="buttonEntrar w-100" onClick={() => this.PDF(this.state.date, this.state.time, this.state.tasa, this.state.userData.nombre_apellido, stock, this.state.tipoP)}> 
              Descargar PDF <FontAwesomeIcon icon={faFileDownload}/>  
            </Button>
              {/*<Button disabled title='Por ahora no está disponible'>Descargar PDF<FontAwesomeIcon icon={faFileDownload}/> </Button>*/}
          </Col>
        </Row>
        <Row>
          <Table bordered hover responsive>
            <thead className="text-center">
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Stock</th>
                <th>Unidad</th>
                {this.state.tipoP.map((tipo,i) =>{
                  if(this.state.tipoP.length === 1){
                    return <th>$</th>
                  }else{
                    return <th key={i}>{tipo}</th>
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {
                stock.map((art, i) => {
                  let stock_act = parseFloat(art.stock_act);
                  let stock1 = (stock_act).toFixed(2);
                  let stock = stock1.toString().replace(/\./g,',');
                  if(art.props){
                    return(
                      <tr key='loading' className="text-center">
                        <td colSpan={this.state.tipoP.length+4}>
                          {art}
                        </td>
                      </tr>
                    )
                  }else{
                    return(<tr key={i}>
                        <td>{art.co_art}</td>
                        <td>{art.art_des}</td>
                        <td>{stock}</td>
                        <td className="text-center">{art.unid.trim()}</td>
                        {this.state.tipoP.map((tipo,i) =>{
                          let util = parseFloat(art[tipo]);
                          let util1 = (util).toFixed(2);
                          let precio = util1.toString().replace(/\./g,',');
                          return <td className="text-center" key={i}>{precio.trim()}</td>
                        })}
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </Table>
        </Row>
      </>
    )
  }
}

export default Stock;