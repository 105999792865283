import React from 'react';
import { Col, Form, FormGroup, Row, Label, Input, Button, Alert } from 'reactstrap';
import { login } from '../../api/info';
import { getTasa, getUserData } from '../../api/info';
import gramocaImg from './../../assets/gramoca.png';

class Login extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      alert:'',
      user:'', 
      pass: '',
    }
  }

  async componentDidMount(){
    if(sessionStorage.getItem('token') !== null){
      const {changeForm}  = this.props;
      let json = {'token': sessionStorage.getItem('token')}
      let tasa = await getTasa();
      let user = await getUserData(json);
      let data = JSON.parse(user);
      changeForm('ok',tasa,data);
    }
  }

  handleChange(e, input) {
    const value = e.target.value;
    this.setState({
      [input]:value
    })
  }

  async iniciar(user,pass,event){
    if(event.key === 'Enter' || event === 'Enter'){
      const {changeForm}  = this.props; let alert = ''
      if(user !== '' && pass !== ''){
        
        //Iniciar sesion y obtener la informacion del usuario: token, rol, nombre_apellido, usuario
        let json = {'user': user, 'pass': pass}
        const res = await login(json);
        //console.log(res);
        const r = JSON.parse(res);

        if(r){
          let tasa = await getTasa(); //Tasa del día
          sessionStorage.setItem('token', r.token)
          changeForm('ok',tasa,r);
        }else if(res === '0'){
          alert = <Alert color="danger" className="text-center">Combinación usuario/contraseña incorrecta</Alert>
        }

      }else{
        alert = <Alert color="danger" className="text-center">Por favor, llene todos los campos.</Alert>
      }

      if(alert !== ''){
        this.setState({
          alert: alert,
        })
      }
    }
  }

  render(){
    return(
      <>
        <Row className="mt-5">
          <Col md={6} className="center">
            <img src={gramocaImg} alt="logo gramoca" className="w-100"/>
          </Col>
        </Row>
        <Row>
          <Col md={7} xs={10} className='center'>
            {this.state.alert}
          </Col>
        </Row>
        <Row onKeyPress={(event) => this.iniciar(this.state.user,this.state.pass,event)}>
          <Col md={6} xs={10} className="center border mt-3  form" style={{marginBottom: '11%'}}>
            <div className="text-center w-100">
              <h2 className="mt-3">Iniciar Sesión</h2>
            </div>
            <Form className="center">
              <FormGroup className=" mt-4 ml-5"> 
                <Label><h5>Usuario:</h5></Label>
                <Input 
                className="w-75 ml-2" 
                placeholder="Nombre de usuario"
                name='user'
                value={this.state.user}
                onChange={event => this.handleChange(event, "user")}
                />
              </FormGroup>
              <FormGroup className=" mt-4 ml-5"> 
                <Label><h5>Contraseña:</h5></Label>
                <Input 
                type="password" 
                className="w-75 ml-2" 
                placeholder="Contraseña"
                name='pass'
                value={this.state.pass}
                onChange={event => this.handleChange(event, "pass")}
              />
              </FormGroup>

              <FormGroup className="mt-4 text-center">
                <Button 
                className="buttonEntrar pl-5 pr-5"
                onClick={() => this.iniciar(this.state.user,this.state.pass,'Enter')}
                >Entrar</Button>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}

export default Login;