import React from 'react';
import { Container, Col, Row, Table, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
          FormGroup, Label, Form, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, CardBody, Card,
          Toast, ToastBody, ToastHeader  } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faCog, faArrowLeft, faUserPlus, faPen} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import { getUserData, adminConf, precios } from '../api/info';
import gramoca from './../assets/gramoca.png';
import out from './../assets/out.png'

class Panel extends React.Component{

  constructor(props){
    let obj = new Date();
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    super(props);
    this.state = {
      userData: [],
      vendedoresData: [],
      preciosList: [],
      preciosAsignados: [],
      preciosEdit: [],
      preciosVendedor: [],
      asignados: [],
      asignadosSaved: [],
      nombreVendedor: '',
      nomVendedor: '',
      usuarioVendedor: '',
      pass: '',
      vpass: '',
      nombreEdit: '',
      usuarioEdit: '',
      passEdit: '',
      vpassEdit: '',
      rol: '',
      alert: '',
      probar: '',
      modalEdit: false,
      modalAdd: false,
      editPass: false,
      openCollapse: false,
      checkEdit: false,
      date : obj.toLocaleDateString(undefined, options),
      time: obj.toLocaleTimeString('en-US'),
    }
  }

  handleChange(e, input) {
    const value = e.target.value;
    this.setState({
      [input]:value
    })
  }

  async componentDidMount(){
    let { userData, preciosList, } = this.state;
    let json = {'token': sessionStorage.getItem('token')};
    let array = await getUserData(json);
    userData = JSON.parse(array);
    let resPrecios = await precios();
    preciosList = JSON.parse(resPrecios);
    if(userData.rol === '0'){
      window.location = '/'
    }
    this.getVendedoresData();
    this.setState({
      userData: userData,
      preciosList: preciosList,
      p1:'',p2:'',p3:'',p4:'',p5:'',
    })
  }

  async getVendedoresData(){
    let { vendedoresData } = this.state;
    let jsn = {'getVendedor': 1}
    let arr = await adminConf(jsn);
    vendedoresData = JSON.parse(arr);
    this.setState({
      vendedoresData: vendedoresData,
    })
  }

  async editar(){
    let { nombreEdit, usuarioEdit, passEdit, vpassEdit, openCollapse, alert, asignados } = this.state;
    let newPass = null; 
    alert = '';
    if(openCollapse === true){ 
      if(passEdit !== '' && passEdit !== ''){
        if(passEdit.length >= 8 && passEdit.length <= 10){
          if(passEdit === vpassEdit){
            newPass = passEdit
          }else{
            alert = <Alert color="danger" className="text-center">Las contraseñas no coinciden</Alert>
          }
        }else{
          alert = <Alert color="danger" className="text-center">La contraseña debe contener un máximo de 10 caracteres y un mínimo de 8</Alert>
        }
      }else{
        alert = <Alert color="danger" className="text-center">Debe elegir una contraseña antes de guardar los cambios</Alert>
      }
    }else{
      alert = ''
    }
    this.setState({
      alert: alert,
      openCollapse: false,
    })
    let json = {
      'nombre': nombreEdit,
      'pass': newPass,
      'usuario': usuarioEdit, 
      'edit': 1,
      'asignados': asignados,
    }
    if(alert === ''){
      Swal.fire({
        title: '¿Estás seguro?',
        text: "Se guardaran los cambios",
        icon: 'question',
        iconHtml: '?',
        showDeniedButton: true,
        confirmButtonColor: '#3085d6',
        deniedButtonColor: '#d33',
        confirmButtonText: 'Aceptar'
      }).then(async (result) => {
        if (result.isConfirmed){
          let edit = await adminConf(json);
          if(edit === '1'){
            Swal.fire(
              'Guardado!',
              'Ha sido editado con éxito',
              'success'
              ).then((result)=>{
                this.setState({
                  modalEdit: false,
                  passEdit: '',
                  vpassEdit: '', 
                  alert: '' ,        
                })
                this.getVendedoresData();
              })
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Por favor, intenta de nuevo',
            })
          }
        }
      })
    }
  }

  async eliminar(id){
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Se eliminará permanentemente",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar'
    }).then(async (result) => {
      if (result.isConfirmed){
        let json = {'delete': 1, 'id': id};
        let res = await adminConf(json);
        if(res === '1'){
          Swal.fire(
            'Eliminado!',
            'Ha sido eliminado con éxito',
            'success'
            ).then((result)=>{
              this.getVendedoresData()
            })
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por favor, intenta de nuevo',
          })
        }
      }
    })
  }

  async agregar(){
    let { nomVendedor, usuarioVendedor, pass, vpass, alert, preciosAsignados ,} = this.state
    if(nomVendedor !== '' &&  usuarioVendedor !== '' &&  pass !== '' &&  vpass !== '' && preciosAsignados !== []){
      if(pass === vpass){
        if(pass.length >= 8 && pass.length <= 10){
          alert = '';
          let newVendedor = {
            add: 1,
            nombre: nomVendedor,
            usuario: usuarioVendedor,
            pass: pass,
            preciosAsignados: preciosAsignados,
          }
          let action = await adminConf(newVendedor);  
          console.log(action) 
          if(action){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: `${nomVendedor} ha sido agregado con éxito`,
              showConfirmButton: false,
              timer: 1500
            })
            this.setState({
              nomVendedor: '',
              usuarioVendedor: '',
              pass: '',
              vpass: '',  
              preciosAsignados: [],    
              modalAdd: false
            })
            this.getVendedoresData();       
          }else{
            alert = <Alert color="danger" className="text-center">El nombre de usuario <b>{usuarioVendedor}</b> ya existe en el registro</Alert>
          }
        }else{
          alert = <Alert color="danger" className="text-center">La contraseña debe contener un máximo de 10 caracteres y un mínimo de 8</Alert>
        }
      }else{
        alert = <Alert color="danger" className="text-center">Las contraseñas no coinciden</Alert>
      }
    }else{
      alert = <Alert color="danger" className="text-center">Debe llenar todos los campos</Alert>
    }

    this.setState({
      alert: alert,
    })
  }

  toggleEdit(nombre, usuario, precios){
    if(precios === undefined){
      precios = []
    }
    this.setState({
      nombreEdit: nombre,
      usuarioEdit: usuario,
      asignados: precios, //Precios asignados al usuario
      asignadosSaved: precios,
      alert:'',
      modalEdit: !this.state.modalEdit,
      p1:'',p2:'',p3:'',p4:'',p5:'',
    })
  }

  editarPrecios(precio, name, asignados1){
    let{asignados} = this.state
    let index = asignados.indexOf(precio);
    if(index > -1){
      asignados.splice(index, 1)
    }else{
      asignados.push(precio)
    }
    this.setState({
      asignados: asignados,
      asignadosSaved: asignados1
    })
  }

  search(){
    if(this.state.nombreVendedor !== ''){
      return this.state.vendedoresData.filter(s => s.nombre_apellido.toLowerCase().includes((this.state.nombreVendedor).toLowerCase()));
    }else{
      return this.state.vendedoresData;
    }
  }

  toggleAdd(){
    this.setState({
      modalAdd: !this.state.modalAdd,
      nomVendedor: '',
      usuarioVendedor: '',
      pass: '',
      vpass: '',
      alert:'',
    })
  }

  editPass(){
    this.setState({
      editPass: !this.state.editPass
    })
  }

  collapse(){
    this.setState({
      openCollapse: !this.state.openCollapse
    })
  }

  checkBox(name){
    let { preciosAsignados } = this.state;
    let byName = document.getElementsByName(name);
    let index = preciosAsignados.indexOf(byName[0].value);
    if(byName[0].checked === true){
      preciosAsignados.push(byName[0].value)
    }else{
      if(index > -1){
        preciosAsignados.splice(index, 1)
      }
    }
    this.setState({
      preciosAsignados: preciosAsignados,
    })
  }

  cerrarSesion(){
    sessionStorage.removeItem('token')
    window.location= '/'
  }

  render(){
    let vendedoresData = this.search(); let {preciosList,preciosEdit,asignadosSaved,asignados} = this.state
    return(
      <Container>
        <Row className="mt-3">
          <Col md={6} sm={6} xs={6}>
            <a href='https://gramoca.com.ve' target='_blank'><img src={gramoca} alt="GRAMOCA, C.A." className="w-100 pointer" title='Ir al sitio oficial'/></a>
          </Col>
          <Col md={6} className="alignEnd">
            <div className="pointer mb-3 mt-2 pull-rigth">
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle 
              caret
              tag="span"
              data-toggle="dropdown"
              aria-expanded={this.state.dropdownOpen}
              > <b>{this.state.userData.nombre_apellido} <FontAwesomeIcon icon={faCog}/></b> 
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem> <Link to={'/'} className='linkClick'> <FontAwesomeIcon icon={faArrowLeft}/> Volver </Link></DropdownItem>
                <DropdownItem onClick={() => this.cerrarSesion()}>Cerrar Sesión <img src={out} alt="cerrar sesión" style={{width: '20px'}}/></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>         
            </div>
            <p style={{marginBottom: '0'}}>{this.state.date}</p>
            <p style={{marginBottom: '0'}}>Hora: {this.state.time}</p>
          </Col>
        </Row>        <hr/>
        <Row className="d-flex mb-5 ">
          <Col md={9} ms={12} sx={12} className="componentAdmin">
            <div className="w-100 d-flex ">
              <div className="w-75" style={{marginLeft: '14%'}}>
                <h4>Listado de Vendedores </h4>
              </div>
              <div className="w-25 ">
                <FontAwesomeIcon 
                  icon={faUserPlus} 
                  color="green" 
                  size="2x" 
                  className="pointer"
                  title="Agregar vendedor"
                  onClick={() => this.toggleAdd()}
                />
              </div>
            </div>
            <hr/>
            <div className="w-100">
                <FormGroup className='d-flex'>
                  <Input 
                    className='ml-2' 
                    name='nombreVendedor' 
                    placeholder='Buscar Vendedor'
                    value={this.state.nombreVendedor}
                    onChange={event => this.handleChange(event, "nombreVendedor")}            
                  />
                </FormGroup>
              </div>
              <Table bordered responsive className='tbodyH'>
              <thead>
                <tr>
                  <th>Nombre y Apellido</th>
                  <th>Nombre de Usuario</th>
                  <th>Precios</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  vendedoresData.map((vendedor, i)=>
                    <tr key={i}>
                      <td>{vendedor.nombre_apellido}</td>
                      <td>{vendedor.usuario}</td>
                      <td>
                        {vendedor.precios.map((precio, j) => <span key={j}>{precio}{vendedor.precios.length !== j+1 ? ', ' : ''} </span>)}
                      </td>
                      <td className="d-flex">
                        <div className="w-50" >
                          <FontAwesomeIcon 
                            icon={faEdit} 
                            className="pointer" 
                            color="green" 
                            title='Editar'
                            onClick={() => this.toggleEdit(vendedor.nombre_apellido,vendedor.usuario,vendedor.precios)}
                          />
                        </div>
                        <div className="w-50">
                          <FontAwesomeIcon 
                            icon={faTrash} 
                            className="pointer" 
                            color="red" 
                            title='Eliminar'
                            onClick={() => this.eliminar(vendedor.id_usuario)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        
        <div id='MODALEDIT'>
          <Modal isOpen={this.state.modalEdit}>
            <ModalHeader>Editar información del vendedor <FontAwesomeIcon icon={faEdit} color="green" /> </ModalHeader>
            <ModalBody style={{paddingLeft: '9%'}}>
              <Form>
                <Row form>
                  <Col md={11}>
                    <FormGroup className='pull-left'>
                      <Label for="">Nombre y apellido</Label>
                      <Input 
                      type="text" 
                      value={this.state.nombreEdit}
                      onChange={event => this.handleChange(event, "nombreEdit") }            
                      />
                    </FormGroup>
                  </Col>
                  <Col md={11}>
                    <FormGroup className='pull-left'>
                      <Label for="">Nombre de Usuario</Label>
                      <Input 
                      disabled
                      type="text" 
                      value={this.state.usuarioEdit}
                      onChange={event => this.handleChange(event, "usuarioEdit") }            
                      />
                    </FormGroup>
                  </Col>
                  <Col md={11} className="mt-1 mb-3 ">
                    <Label>Modifique precios permitidos para este usuario</Label>
                    <Row>
                      {
                        preciosList.map((precio,i) => {
                          if(this.state['p'+precio.precio] === ''){
                            asignados.map((pe, i) => {
                              if(pe === precio.precio){
                                let e = {'target': {'value': true}}
                                this.handleChange(e,'p'+precio.precio)
                              }
                            })
                          }
                          return(
                            <Col md={2} key={i}>
                              <FormGroup check className="pr-3" >
                                <Label check>
                                  <Input 
                                    checked={this.state['p'+precio.precio]}
                                    type="checkbox" 
                                    value={precio.precio}
                                    id={'precio'+precio.precio} 
                                    name={'precio'+precio.precio}
                                    onClick={() => this.editarPrecios(precio.precio, 'precio'+precio.precio, preciosEdit)}
                                    onChange={() => this.handleChange({'target': {'value':this.state['p'+precio.precio] === true ? false : true}},'p'+precio.precio)}
                                    />P{precio.precio}
                                </Label>
                              </FormGroup>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                  <Col md={11}>
                    <span id="toggler" className="pointer" onClick={() => {this.editPass(); this.collapse()}} style={{ marginBottom: '1rem' }}>
                      Cambiar contraseña <FontAwesomeIcon icon={faPen}/>
                    </span>
                    <Collapse isOpen={this.state.openCollapse}>
                      <Card>
                        <CardBody style={{paddingLeft: 'revert'}}>
                          <Row>
                            <Col md={7}>
                              <Col md={12}>
                                <FormGroup className='pull-left'>
                                  <Label for="">Nueva contraseña</Label>
                                  <Input 
                                  type="password" 
                                  placeholder="Nueva contraseña"
                                  value={this.state.passEdit}
                                  onChange={event => this.handleChange(event, "passEdit") }            
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={12}>
                                <FormGroup className='pull-left'>
                                  <Label  for="">Confirmar Contraseña</Label>
                                  <Input 
                                  type="password" 
                                  placeholder="Confirmar contraseña"
                                  value={this.state.vpassEdit}
                                  onChange={event => this.handleChange(event, "vpassEdit") }            
                                  />
                                </FormGroup>
                              </Col>
                            </Col>
                            <Col md={5} xs={12} ms={12} style={{marginTop: '8%'}}>
                              <Toast style={{maxWidth:'100%'}}>
                                <ToastBody>
                                  La contraseña debe contener un mínimo 8 caracteres y un máximo de 10
                                </ToastBody>
                              </Toast>
                            </Col> 
                          </Row>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Col>
                </Row>
              </Form>
              {this.state.alert}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.editar()}>Guardar Cambios</Button>
              <Button color="secondary" onClick={() => this.toggleEdit('','', asignadosSaved)}>Cancelar</Button>
            </ModalFooter>
          </Modal>
        </div>

        <div id='MODALADD'>
        <Modal isOpen={this.state.modalAdd}>
            <ModalHeader className="text-center">
              Agregar Vendedor <FontAwesomeIcon icon={faUserPlus} color="green"  />
            </ModalHeader>
            <ModalBody style={{paddingLeft: '9%'}}>
              <Form>
                <Row form style={{marginLeft: 'auto'}}>
                  <Col md={11}>
                    <FormGroup className='pull-left'>
                      <Label for="">Nombre y apellido</Label>
                      <Input 
                      type="text" 
                      placeholder='Nombre y apellido del nuevo vendedor'
                      value={this.state.nomVendedor}
                      onChange={event => this.handleChange(event, "nomVendedor") }            
                      />
                    </FormGroup>
                  </Col>
                  <Col md={11}>
                    <FormGroup className='pull-left'>
                      <Label for="">Nombre de Usuario</Label>
                      <Input 
                      type="text" 
                      placeholder='Nombre de usuario para el nuevo vendedor'
                      value={this.state.usuarioVendedor}
                      onChange={event => this.handleChange(event, "usuarioVendedor") }            
                      />
                    </FormGroup>
                  </Col>
                  <Col md={11} className="mt-1 mb-3 ">
                    <Label>Seleccione precios permitidos para este usuario</Label>
                    <Row>
                      {
                        preciosList.map((precio,i) => {
                          return(
                            <Col md={2} key={i}>
                              <FormGroup check className="pr-3" >
                                <Label check>
                                  <Input 
                                    type="checkbox" 
                                    value={precio.precio}
                                    id={'precio'+precio.precio} 
                                    name={'precio'+precio.precio}
                                    onClick={() => this.checkBox('precio'+precio.precio)}
                                    />P{precio.precio}
                                </Label>
                              </FormGroup>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Col>
                  <Row className=" ">
                    <Col md={7} xs={12} ms={12} style={{paddingRight:'0'}}>
                      <Col md={12} style={{paddingLeft:'2%'}}>
                        <FormGroup className='pull-left'>
                          <Label for="">Contraseña</Label>
                          <Input 
                          type="password" 
                          placeholder='Ingrese contraseña'
                          value={this.state.pass}
                          onChange={event => this.handleChange(event, "pass") }            
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12} style={{paddingLeft:'2%'}}>
                        <FormGroup className='pull-left'>
                          <Label  for="">Verificar Contraseña</Label>
                          <Input 
                          type="password" 
                          placeholder='Verificar contraseña'
                          value={this.state.vpass}
                          onChange={event => this.handleChange(event, "vpass") }            
                          />
                        </FormGroup>
                      </Col>
                      </Col>
                      <Col md={4} xs={12} ms={12} style={{marginTop: '7%'}}>
                        <Toast style={{fontSize: '14px'}}>
                          <ToastBody>
                            La contraseña debe contener un mínimo 8 caracteres y un máximo de 10 <br/>
                          </ToastBody>
                        </Toast>
                      </Col> 
                  </Row>
                </Row>
              </Form>
              {this.state.alert}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.agregar()}>Guardar</Button>
              <Button color="secondary" onClick={() => this.toggleAdd()}>Cancelar</Button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
    )
  }
}

export default Panel;