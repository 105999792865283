import React from 'react';
import { Col, Container, Row } from 'reactstrap';

class Footer extends React.Component{

  state = {
    año: (new Date).getFullYear()
  }

  render(){
    return(
      <>
        <Container fluid className="footer">
          <Row>
            <Col md={12} className="text-center" style={{fontSize:'80%'}}>
              <div><i>Antes de emitir cotización validar existencia</i></div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center" style={{fontSize:'90%'}}>
              &copy; {this.state.año} Todos los derechos reservados | GRAMOCA, C.A.
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default Footer;