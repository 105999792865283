import React from 'react';
import { Col, Row, Table, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileDownload, faEdit, faCog} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { getStock, getUserData } from '../../api/info';
import gramoca from './../../assets/gramoca.png';
import loading from './../../assets/loading.gif'
import out from './../../assets/out.png'
import { jsPDF } from "jspdf";
import {pdf} from './../../api/constants';

class PDF extends React.Component{

  pdf(){
    var doc = new jsPDF();
    doc.text("Hello world!", 20, 20);
    doc.text("This is client-side Javascript, pumping out a PDF.", 20, 30);
    doc.addPage("a6", "l");
    doc.text("Do you like that?", 20, 20);
    doc.save("document.pdf");
  }

  render(){
    return(<Button onClick={() => this.pdf()}>pdf</Button>)
  }
}

export default PDF;