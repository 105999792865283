import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Inventario from './Inventario/Inventario';
import Panel from './AdminPanel/Panel';
import PDF from './Inventario/Components/PDF'

ReactDOM.render(
  <BrowserRouter >
    <Switch>
      <Route exact path='/'render={props => <Inventario {...props} />}/>
      <Route exact path='/adminPanel' component={Panel}/>
      <Route exact path='/pdf' render={props => <PDF {...props} />}/>
      <Route exact path='*' render={props => <Inventario {...props} />}/>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals