import React from 'react';
import { Container } from 'reactstrap';
import Footer from '../Footer/Footer';
import Login from './Components/Login';
import Stock from './Components/Stock';
import { getTasa, getUserData } from '../api/info';
import Panel from './../AdminPanel/Panel';

class Inventario extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      componente: '',
      fluid: false, 
      componente: '',
      footer: '',
    }
    this.changeForm = this.changeForm.bind(this);
    this.getWidth = this.getWidth.bind(this);
  }

  async componentDidMount(){
    let {componente} = this.state;
    if(sessionStorage.getItem('token') !== null){
      const {changeForm}  = this.props;
      let json = {'token': sessionStorage.getItem('token')}
      let tasa = await getTasa();
      let user = await getUserData(json);
      let data = JSON.parse(user);
      componente = <Stock tasa={tasa} dataUser={data}/>
    }else{
      componente = <Login changeForm={this.changeForm}/>
    }
    this.setState({
      componente: componente,
      footer: <Footer/>
    })

  }

  changeForm(res,tasa,user){
    let {componente} = this.state;
    if(res === 'ok'){
      componente = <Stock tasa={tasa} dataUser={user}/>
    }
    this.setState({
      componente: componente
    })
  }

  getWidth(){
    let {fluid} = this.state
    if(window.screen.width >= 580 && window.screen.width <= 767){
      fluid = true
    }else{
      fluid = false
    }
    if(fluid !== this.state.fluid){
      //console.log(window.screen.width)
      this.setState({
        fluid:fluid
      })
    }
  }

  render(){
    return(
      <>
        <Container fluid={this.state.fluid} onClick={this.getWidth}>
          {this.state.componente}
        </Container>
        {this.state.footer}
      </>
    )
  }
}

export default Inventario;