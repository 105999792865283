//GRAMOCA
export const url = 'http://inventario.gramoca.com.ve/';
export const login = `${url}back_gramocainv/controllers/login.php`;
export const tasa = `${url}back_gramocainv/controllers/getTasa.php`;
export const stock = `${url}back_gramocainv/controllers/getStock.php`; 
export const pdf = `${url}back_gramocainv/PDF/generar.php?`;
export const userData = `${url}back_gramocainv/controllers/getUserData.php`;
export const admin = `${url}back_gramocainv/controllers/admin.php`;
export const precios = `${url}back_gramocainv/controllers/getPrecios.php`;

//LOCAL
// export const url = 'http://localhost/gramoca/';
// export const login = `${url}back_gramocainv/controllers/login.php`;
// export const tasa = `${url}back_gramocainv/controllers/getTasa.php`;
// export const stock = `${url}back_gramocainv/controllers/getStock.php`;
// export const pdf = `${url}back_gramocainv/PDF/prueba.php`;
// export const userData = `${url}back_gramocainv/controllers/getUserData.php`;
// export const admin = `${url}back_gramocainv/controllers/admin.php`;
// export const precios = `${url}back_gramocainv/controllers/getPrecios.php`;

