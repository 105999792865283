import $ from "jquery";
import * as cons from './constants';

export async function login(data) {
  try {
    const res = $.post(cons.login, data, function(resp){})
    return res
  }
  catch(err) {
    return err;
  }
}

export async function getTasa() {
  try {
    const res = $.get(cons.tasa)
    return res
  }
  catch(err) {
    return err;
  }
}

export async function getStock(precios) {
  try {
    const res = $.post(cons.stock, precios, function(){})
    return res
  }
  catch(err) {
    return err;
  }
}

export async function getPdf(data) {
  try {
    const res = $.post(cons.pdf, data, function(resp){})
    return res
  }
  catch(err) {
    return err;
  }
}

export async function getUserData(json) {
  try { 
    const res = $.post(cons.userData, json, function(resp){})
    return res
  }
  catch(err) {
    return err;
  }
}

export async function adminConf(json) {
  try { 
    const res = $.post(cons.admin, json, function(resp){})
    return res
  }
  catch(err) {
    return err;
  }
}

export async function precios() {
  try {
    const res = $.get(cons.precios)
    return res
  }
  catch(err) {
    return err;
  }
}
